<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        考试报告
      </template>
      <template #input>
        <a-input placeholder="请输入报告名称" v-model="queryParams.name"/>
        <a-date-picker placeholder="请选择考试日期" @change="onDataChange" />
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch">搜索</a-button>
        <a-button class="all_boder_btn" @click="goScore()" v-hasPermi="['exam:scorelevel:edit']">分数段配置</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="" @change="callback">
        <a-tab-pane v-for="(item) in tabData" :key="item.configId" :tab="item.abbreviation"></a-tab-pane>
      </a-tabs>
      <a-table
      class="table-template"
      :loading="loading"
      :rowKey="item=>item.signupId"
      :columns="columns"
      :scroll="{ x: 1200 }"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.current,  
        defaultPageSize:queryParams.size, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.current - 1) * 10 + i + 1 }}
          </div>
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <router-link to @click.native="goNamePage(item)">参考名单</router-link>
            <span v-hasPermi="['exam:report:export']">|</span>
            <router-link to @click.native="onReportSet(item)" v-hasPermi="['exam:report:export']">导出</router-link>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 导出考试报告~设置 -->
    <a-modal v-model="isReportSetShow" align="center"  title="报告设置" >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">报告名称：</span>
          <a-input class="right" v-model="reportObj.reportName" placeholder="请输入报告名称" />
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">考试地点：</span>
          <a-input class="right" v-model="reportObj.examPlace" placeholder="请输入考试地点" />
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">监考人员：</span>
          <a-input class="right" v-model="reportObj.invigilator" placeholder="请输入监考人员" />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onModify">确认</a-button>
        <a-button  @click="isReportSetShow=false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "报告名称",
    align:'center',
    dataIndex: "reportName"
  },
  {
    title: "考试日期",
    align:'center',
    dataIndex: "examDate"
  },
  {
    title: "参考人数",
    align:'center',
    dataIndex: "examineeNum"
  },
  {
    title: "通过人数",
    align:'center',
    dataIndex: "passNum"
  },
  {
    title: "未通过人数",
    align:'center',
    dataIndex: "notPassNum"
  },
  {
    title: "通过率",
    align:'center',
    dataIndex: "passRate"
  },
  {
    title: "操作",
    fixed: "right",
    align:'center',
    width: "130px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      tableData:[], // 列表数据
      tabData:[], // tab列表
      tabName:'', // tab名称
      loading:false, // 列表加载
      total: 0, // 总数量
      queryParams:{
        configId: 0,  //tabID,
        current: 1, // 页码
        size: 10, // 页数
        examDate: '',  // 考试时间
        name: '' // 报告名称
      },
      reportObj:{ // 报告导出设置
        reportName: '', // 报告名称
        examPlace: '', // 考试地点
        invigilator: '', // 监考人
        signupId: '' //考试id
      },

      isReportSetShow:false,  // 修改考试名额
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.current = e.current
      this.queryParams.size = e.pageSize
      this.getManageList()
    },
    // tab切换
    callback(e){
      this.queryParams.current = 1
      this.queryParams.configId = e
      this.getManageList()
    },
    onDataChange(e,v){
      this.queryParams.examDate = v
    },
    // 高级检索
    onSearch(){
      this.queryParams.current = 1
      this.getManageList()
    },

    // 跳转参考人员名单列表
    goNamePage(e){
      this.$router.push({ path: "/admin/Examination/ReferenceList",query: { signupId:e.signupId, reportName: e.reportName } })
    },

    goScore(id){
      this.$router.push({ path: "/admin/Examination/ScoreConfiguration"})
    },
    // 查询tab列表
    getTabList(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/config/exam/tab/list",
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.tabData = res.data
          this.queryParams.configId = res.data[0].configId
          this.getManageList()
        } else {
          this.tabData = []
        }
      })
    },
    // 报告导出设置
    onReportSet(e){
      this.reportObj.reportName = e.reportName
      this.reportObj.signupId = e.signupId
      this.isReportSetShow = true
    },
    // 导出考试报告~设置
    onModify(){
      if(!this.reportObj.examPlace){
        this.$message.warning('请输入考试地点');
        return
      }
      if(!this.reportObj.invigilator){
        this.$message.warning('请输入监考人员');
        return
      }
      window.open(this.$config.target+"/hxclass-management/exam/report/examinee/export?examPlace=" + encodeURIComponent(this.reportObj.examPlace) +
      "&invigilator=" + encodeURIComponent(this.reportObj.invigilator) +
      "&reportName=" + encodeURIComponent(this.reportObj.reportName) + 
      "&signupId=" + encodeURIComponent(this.reportObj.signupId) 
      );
      this.$message.success('导出成功');
      this.isReportSetShow = false
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/report/list",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
    },
    
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getTabList()
  },
  // 生命周期-实例挂载后调用
  mounted () {
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    isReportSetShow(){
      if(!this.isReportSetShow){
        this.reportObj.examPlace = ''
        this.reportObj.invigilator = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.all_content_box{
  .line-item{
    margin-bottom: 20px;
    display: flex;
    .all_left_name{
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .line-item:last-child{
    margin-bottom: 0;
  }
  .right{
    flex: 1;
    height: 40px;
    /deep/.ant-input{
      height: 40px;
    }
  }
  textarea.ant-input{
    flex: 1;
  }
}
.table-template{
  margin-top: 0;
}
/deep/ .ant-modal-footer{
  text-align: center;
}
</style>
